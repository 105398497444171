body {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

/* fade for inner chart labels to match animation */
.fadeText2s {
	animation: fadeIn linear 2s;
	-webkit-animation: fadeIn linear 2s;
	-moz-animation: fadeIn linear 2s;
	-o-animation: fadeIn linear 2s;
	-ms-animation: fadeIn linear 2s;
  }
  
  @keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }

  .inlineChart {
	max-width: 150px;
	max-height: 150px;
  }